import { motion } from "framer-motion";
import { RiGithubLine, RiLink } from "react-icons/ri";
import Menu from "../constants/projectConstants";

const Portfolio = () => {
  return (
    <section
      className="max-w-[1080px] px-[15px] mx-auto pt-[7rem] pb-[2rem]"
      id="portfolio"
    >
      <h2
        style={{ fontSize: "var(--h1-font-size)" }}
        className="ml-[0.875rem] mb-[3.75rem] relative"
      >
        Projects
      </h2>

      <div className="grid grid-cols-[310px] justify-center gap-y-[1.875rem] md:grid-cols-[repeat(2,330px)] md:justify-center md:gap-x-[1.875rem] md:gap-y-[1.875rem] lg:grid-cols-3 lg:gap-[1.875rem]">
        {Menu.map((elem) => {
          const { id, image, title, category, url, repositoryUrl } = elem;

          return (
            <motion.div
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="group relative overflow-hidden rounded-[var(--border-radius)] shadow-[var(--shadow)]"
              key={id}
            >
              <div>
                <img src={image} alt="" height="267" />
                <div className="group-hover:opacity-90 absolute inset-0 bg-[#6c6ce5] transition-opacity duration-300 opacity-0"></div>
              </div>
              <h3 className="group-hover:opacity-100 group-hover:translate-y-0 absolute top-[3.75rem] text-white text-[var(--h3-font-size)] m-0 mb-[0.98rem] px-[1.25rem] transform translate-y-[30px] duration-300 opacity-0">
                {title}
              </h3>
              <button
                onClick={() => {
                  const newWindow = window.open(
                    url,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
                className="group-hover:opacity-100 absolute bottom-[1.5rem] left-[1.5rem] text-white text-[var(--h3-font-size)] flex justify-center items-center bg-[#ffd15c] h-[40px] w-[40px] cursor-pointer rounded-full text-center leading-[42px] transition-opacity duration-300 opacity-0"
              >
                <RiLink />
              </button>

              <button
                onClick={() => {
                  const newWindow = window.open(
                    repositoryUrl,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
                className="group-hover:opacity-100 absolute bottom-[1.5rem] left-[4.5rem] text-white text-[var(--h3-font-size)] flex justify-center items-center bg-[#ffd15c] h-[40px] w-[40px] cursor-pointer rounded-full text-center leading-[42px] transition-opacity duration-300 opacity-0"
              >
                <RiGithubLine />
              </button>
            </motion.div>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
