import { FaGithub, FaLinkedinIn, FaTwitter } from "react-icons/fa";

const HeaderSocials = () => {
  return (
    <div className="flex gap-x-7 my-6 mx-0 z-50">
      <button
        className="text-[var(--title-color)] text-[1.3rem] transition duration-300 hover:text-[#181717]"
        onClick={() =>
          window.open(
            "https://github.com/manan228",
            "_blank",
            "noopener,noreferrer"
          )
        }
      >
        <FaGithub />
      </button>

      <button
        className="text-[var(--title-color)] text-[1.3rem] transition duration-300 hover:text-[#0077B5]"
        onClick={() =>
          window.open(
            "https://www.linkedin.com/in/manan-shah-724411185/",
            "_blank",
            "noopener,noreferrer"
          )
        }
      >
        <FaLinkedinIn />
      </button>

      <button
        className="text-[var(--title-color)] text-[1.3rem] transition duration-300 hover:text-[#1DA1F2]"
        onClick={() =>
          window.open(
            "https://x.com/Manan17344116",
            "_blank",
            "noopener,noreferrer"
          )
        }
      >
        <FaTwitter />
      </button>
    </div>
  );
};

export default HeaderSocials;
