const ScrollDown = () => {
  return (
    <div className="absolute bottom-10 left-0 w-full flex justify-center">
      <div>
        <span
          style={{
            fontSize: "var(--small-font-size)",
            color: "var(--title-color)",
          }}
        >
          Scroll Down
        </span>
        <span className="border-2 border-[#454360] block h-[1.6rem] w-[1.25rem] mx-auto mt-[0.75rem] rounded-[1rem] relative">
          <span className="wheel bg-[var(--title-color)] rounded-full w-[0.25rem] h-[0.25rem] absolute top-[0.5rem] left-1/2 transform -translate-x-1/2"></span>
        </span>
      </div>
    </div>
  );
};

export default ScrollDown;
