import "./Home.css";
import { PROFILE_PIC } from "../constants/constants";
import HeaderSocials from "./HeaderSocials";
import ScrollDown from "./ScrollDown";
import Shapes from "./Shapes";

const Home = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      className="relative min-h-screen flex items-center justify-center max-w-[1080px] px-[15px] mx-auto"
      id="home"
    >
      <div className="flex flex-col items-center">
        <img src={PROFILE_PIC} alt="My Pic" className="mb-6" width="120" />
        <h1
          style={{
            fontSize: "var(--h1-font-size)",
            fontWeight: "var(--font-bold)",
          }}
          className="mb-2"
        >
          Manan Shah
        </h1>
        <span>I'm a Software Engineer</span>

        <HeaderSocials />

        <button
          style={{
            backgroundColor: "var(--first-color)",
            fontWeight: "var(--font-bold)",
          }}
          className="py-3 px-8 leading-none rounded-[1.875rem] shadow border border-transparent text-white inline-block z-50 btn"
          onClick={scrollToContact}
        >
          Contact Me
        </button>

        <ScrollDown />
      </div>

      <Shapes />
    </section>
  );
};

export default Home;
