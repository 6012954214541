import React from "react";
import "./About.css";
import AboutBox from "./AboutBox";
import { TECHNOLOGIES, MANAN_SHAH, PROFILE_PIC } from "../constants/constants";

const About = () => {
  const downloadResume = async () => {
    window.open(MANAN_SHAH, "_blank");
  };

  return (
    <section
      className="max-w-[1080px] px-[15px] mx-auto section scroll-m-[-70px]"
      id="about"
    >
      <h2 className="section__title">About Me </h2>

      <div className="about__container grid">
        <img src={PROFILE_PIC} alt="" className="about__img" />

        <div className="about__data grid">
          <p className="about__description">
            Results-driven software engineer with 2.5 years of experience,
            demonstrating expertise in designing, developing, and leading
            end-to-end projects. Recognized for successful project development of
            the Cal State Fullerton Appointment app and the VibeSea application,
            overseeing tasks from initial design to production deployment.
            Skilled in creating applications from scratch, designing databases,
            managing cloud infrastructure, and utilizing Figma for design, the
            SCRUM process, and JIRA for task management. Proficient in
            delegating tasks to junior developers and ensuring flawless project
            execution. I aim to leverage my extensive technical skills and
            project management experience to drive impactful and innovative
            solutions.
            <br />
            <br />
            Here are a few technologies I’ve been working with recently:
          </p>
          <ul className="about__list">
            {TECHNOLOGIES.map((tech) => (
              <li key={tech}>{tech}</li>
            ))}
          </ul>
          <button
            className="py-3 px-8 leading-none rounded-[1.875rem] shadow border border-transparent text-white inline-block z-50 btn"
            onClick={downloadResume}
          >
            Donwload CV
          </button>

          {/* <div className="about__skills grid">
                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Development</h3>
                                <span className="skills__number">90%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage development">

                                </span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">UI/UX Design</h3>
                                <span className="skills__number">80%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage ui__design">

                                </span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Photography</h3>
                                <span className="skills__number">60%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage photography">

                                </span>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>

      <AboutBox />
    </section>
  );
};

export default About;
