import axios from "axios";
import { useEffect } from "react";
import { BASE_URL } from "../components/constants/constants";

const useUpdateVisitorCount = () => {
  useEffect(() => {
    updateVisitorCount();
  }, []);

  const updateVisitorCount = async () => {
    try {
      await axios.get(`${BASE_URL}`);
    } catch (err) {
      console.log(err);
    }
  };
};

export default useUpdateVisitorCount;
