import { useState } from "react";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Resume from "./components/resume/Resume";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";
import useUpdateVisitorCount from "./hooks/useUpdateVisitorCount";

function App() {
  const [theme, setTheme] = useState(() => {
    const theme = localStorage.getItem("theme");
    return theme ? theme : "dark";
  });

  useUpdateVisitorCount();

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  return (
    <div data-theme={theme}>
      <Sidebar theme={theme} switchTheme={switchTheme} />
      <main className="ml-0 lg:ml-[110px]">
        <Home />
        <About />
        <Resume />
        <Portfolio />
        {/* <Testimonials /> */}
        {/* <Blog /> */}
        <Contact theme={theme} />
      </main>
    </div>
  );
}

export default App;
