const WorkExperience = [
  {
    id: 1,
    title: "Software Engineer - Intern",
    company: "VibeSea",
    yearsActive: "May 2024 - Present",
    information: [
      "Building VibeSea from scratch",
      "Spearheaded the development of the entire software stack, including frontend, backend, and database design using ReactJS, NodeJS, ExpressJS, Redis, and MongoDB",
      "Designed and implemented CI/CD pipelines, managed cloud infrastructure, and ensured seamless deployment of applications",
      "Created intuitive UI/UX designs, wireframes, and prototypes for the application, ensuring a user-friendly experience using Figma for designing, ReactNative for mobile application",
      "Oversaw project planning, task delegation, and progress tracking to ensure timely delivery of features",
      "Worked closely with stakeholders to gather requirements, provide updates, and incorporate feedback effectively",
      "Technologies Used: ReactJS, React Native, Yarn, NodeJS, ExpressJS, Redis, MongoDB, Google Firebase, TypeScript, JavaScript, Husky, ESLint",
    ],
  },
  {
    id: 2,
    title: "Research Assistant",
    company: "California State University - Fullerton",
    yearsActive: "April 2024 - Present",
    information: [
      "Designed and developed a comprehensive appointment booking system from scratch for Cal State Fullerton, utilizing the MERN stack (MongoDB, ExpressJS, ReactJS, NodeJS)",
      "Engineered a user-friendly interface to facilitate appointment scheduling between undergraduate/graduate students and their professors/advisors",
      "Implemented robust backend services to manage user authentication, appointment scheduling, and data storage",
      "Integrated Google Firebase for real-time database updates and authentication",
      "Conducted thorough testing and debugging to maintain high performance and security standards"
    ],
  },
  {
    id: 3,
    title: "Teaching Associate",
    company: "California State University - Fullerton",
    yearsActive: "January 2024 - Present",
    information: [
      "Teaching undergraduate students Foundation to Software Engineering (CPSC 362) and Web Backend (CPSC 449)",
      "Declaring and defining the Software Engineering Process, Backend , CI/CD Pipelines, HTTP Requests, CORS, Database",
      "Implemented a new process for Software Development and testing it based on 7 projects",
      "Taught Authentication, secure tokens, Container Orchestration (Kubernetes), Software Processes, Backend, etc"
    ],
  },
  {
    id: 4,
    title: "Software Engineer",
    company: "FlexiLoans",
    yearsActive: "August 2022 - July 2023",
    information: [
      "Implemented the entire payment gatway for all the transaction that goes through Flexiloans by integrating various apis of bank, UPI transaction, razorpay transactions, etc",
      "Migrated APIs to NodeJs",
      "Improved the application speed by almost 20% using React.lazy by optimizing the application",
      "Superheaded and optmised the implementation of generation of transcations PDF for sepecific user by implementing async operations in NodeJs",
      "Designed dashboard component and it's functionality for the internal use of employees",
      "Implemented ESLint, SonarLint, SonarQube and husky script for better code practices across 5+ repository",
    ],
  },
  {
    id: 5,
    title: "Software Engineer",
    company: "Sharpener",
    yearsActive: "September 2022 - April 2024",
    information: [
      "Worked on building subscription feature for the organization",
      "•	Designed the whole shell terminal for each container having deployments, pods and services created for each user by implementing Container Orchestration (Kubernetes)",
      "Spearheaded the creation and successful implementation of Sharpener.tech's MERN stack development course, resulting in a 50% increase in enrollment and a 20% boost in student satisfaction scores",
      "Implemented tracking system for mentors at Sharpener to track student progress"
    ],
  },
  {
    id: 6,
    title: "Software Engineer - Intern",
    company: "eInfochips (An Arrow Electronics)",
    yearsActive: "August 2022 - July 2022",
    information: [
      "Collaborated on 3+ projects, with immense hands-on new learning that was then applied to projects and managed to deliver essential output in a limited time",
      "Designed and optimized the sidebar component using useCallback and React.memo to increase the efficiency by 30%",
    ],
  },
];

export default WorkExperience;
